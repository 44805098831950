<template>
  <div>
    <span style="color:#575757;font-size:12px">最多选择5项技能</span>
    <!--			<strong>{{ title }}</strong>-->
    <!-- <el-select style="margin-left: 10px;" v-model="skill_cat_id" @change="changeSkillCat" placeholder="请选择技能分类">
      <el-option-group v-for="groupCat in categorys" :key="groupCat.id" :label="groupCat.name">
        <el-option v-for="cat in groupCat.children" :key="cat.id" :label="cat.name" :value="cat.id"></el-option>
      </el-option-group>
    </el-select> -->
    <div class="line">
      <label class="title" v-if="showTitle"> 所属行业 </label>
      <el-cascader
          v-model="skill_cat_id"
          :options="categorys"
          :props="{ label: 'name', value: 'id', children: 'children', expandTrigger: 'hover' }"
          @change="changeSkillCat"
          placeholder="请选择行业技能分类"
          :style="catStyle"
          clearable/>
    </div>
    <div class="line" style="margin-bottom: unset">
      <label class="title" v-if="showTitle"> 所需技能 </label>
      <div class="skill-select">
        <!-- 最多可以选5个技能 -->
        <el-select
            v-model="skill_ids"
            multiple
            filterable
            remote
            reserve-keyword
            allow-create
            default-first-option
            placeholder="点击输入技能"
            :loading="loading"
            :multiple-limit="5"
            @remove-tag="removeSkill"
            @change="changeSkill"
            @visible-change="toBackData"
            :remote-method="remoteMethod"
            :style="styleAttrs"
        >
          <el-option v-for="item in skills" :key="item.id" :label="item.name" :value="item.id"/>
        </el-select>
      </div>
    </div>
    <p class="skill-p" v-if="recommand_skills.length>0">
      <span :class="showTitle?'text':''"> 推荐技能： </span>
      <span
          class="skill-tag"
          @click="clickAppendSkill(itemRecommnd)"
          type="primary"
          style="font-size: 12px;margin-left:10px;"
          v-for="(itemRecommnd, indexRecommnd) in recommand_skills"
          :key="indexRecommnd"
      >
        {{ itemRecommnd.name }}
      </span>
    </p>
  </div>
</template>

<script>
import {getSkills, getSkillsCategory, getRecommandskills} from '@/api/task';

export default {
  props: {
    title: {
      default: '技能分类'
    },
    checked_skill_cat_id: {
      default: 0
    },
    checked_skills: {},
    styleAttrs: {
      default: {width: '620px'}
    },
    catStyle: {
      type: Object,
      default: () => {
      }
    },
    showTitle: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    checked_skill_cat_id(newval, oldval) {
      if (newval != oldval) {
        this.skill_cat_id = newval;
        this.loadSkills({cat_id: newval}, true);
      }
    },
    checked_skills(newval) {
      if (newval.length > 0) {
        newval.map(item => {
          this.clickAppendSkill(item)
        })
      }
    },
    styleAttrs(newval) {
      if (newval) {
        this.styleAttrs = newval;
      }
    }
  },
  created() {
    this.loadAllSkillCategory();
  },
  data() {
    return {
      skills: [],
      recommand_skills: [],
      categorys: [],
      loading: false,
      skill_ids: [],
      skill_cat_id: '',
      current_skills: []
    };
  },
  methods: {
    async loadSkills(data, isLoadSkills = false) {
      const res = await getSkills(data);
      if (res.statusCode == 200) {
        if (isLoadSkills) {
          if (this.checked_skills.length > 0) {
            this.checked_skills.map(item => {
              res.data.list.map(ii => {
                if (ii.id == item.id) {
                  //this.skills.push( ii )
                }
              })
            })
          } else {
            this.skills = res.data.list;
          }
          //this.toBackData();
        }
        this.recommand_skills = res.data.list;
        this.loading = false;
      }
    },
    async loadAllSkillCategory() {
      const res = await getSkillsCategory();
      if (res.statusCode == 200) {
        this.categorys = res.data;
      }
    },
    async searchSkills(data) {
      this.loading = true;
      const res = await getRecommandskills(data);
      if (res.statusCode == 200) {
        this.skills = res.data.skills;
        //this.recommand_skills = res.data.recommand_skills.list;
        this.loading = false;
      }
    },
    async changeSkillCat(catId) {
      // 先清空 推荐技能
      this.recommand_skills = [];
      if (typeof catId == 'undefined') {
        return;
      } else {
        catId = catId[catId.length - 1];
        this.skill_cat_id = catId;
        this.toBackData(); // 更新分类
        this.loading = true;
        let obj = {};
        this.categorys.forEach(item => {
          var ress = item.children.find(element => {
            return element.id === catId;
          });
          if (ress != 'undefined') {
            obj = ress;
          }
        });

        if (typeof catId == 'undefined') {
          return;
        }
        const res = await getSkills({cat_id: catId});
        if (res.statusCode == 200) {
          this.recommand_skills = res.data.list;
          this.loading = false;
        }
      }
    },
    changeSkill(changeSkills) {
      this.skills.map(item => {
        changeSkills.map(changeId => {
          if (changeId == item.id && this.skill_ids.indexOf(changeId) != -1) { // 如果已选中不在 已选中 id 中同步数据
            var currentIds = []
            this.current_skills.map(citem => {
              currentIds.push(citem.id)
            })
            if (currentIds.indexOf(changeId) == -1) {// 已有的不在添加
              this.current_skills.push(item)
            }
          }
        })
      })
    },
    remoteMethod(query) {
      if (query !== '') {
        this.loading = true;
        setTimeout(() => {
          this.loading = false;
          this.searchSkills({subject: query, content: query});
        }, 200);
      } else {
        this.skills = [];
      }
    },
    // 点击追加推荐技能
    clickAppendSkill(skill) {
      if (this.skill_ids.length > 5) {
        this.$message.error('最多可选5个技能');
        return;
      }
      var canAppend = true;
      this.skills.map(ele => {
        if (ele.id == skill.id) {
          canAppend = false;
        }
      });
      if (canAppend) {
        this.skills.push(skill);
        this.skill_ids.push(skill.id);
        this.current_skills.push(skill);
        this.toBackData();
      }
    },
    removeSkill(id) {
      this.skill_ids.splice(id, 1);
      this.current_skills.map((item, index) => {
        if (item.id == id) {
          this.current_skills.splice(index, 1);
        }
      });
      this.toBackData();
    },
    toBackData(skillids) {
      if (typeof skillids == 'boolean' || typeof skillids == 'undefined') {
      } else {
        this.skill_ids = skillids
        this.current_skills = skillids.filter(skid => {
          this.skills.forEach(skitem => {
            if (skid == skitem.id) {
              return skid;
            }
          });
        });
      }

      let data = {
        skill_ids: this.skill_ids,
        skill_cat_id: this.skill_cat_id,
        skills: this.current_skills
      };

      this.$emit('skillCallBack', data);
    }
  }
};
</script>

<style lang="less" scoped>
.skill-p {
  height: 30px;
  //display: flex;
  align-items: center;
  overflow: hidden;

  .text {
    margin-left: 80px;
  }
}

.skill-tag {
  cursor: pointer;
  color: #00ace6;
}

.line {
  display: flex;
  align-items: center;
  justify-content: left;
  margin-bottom: 20px;

  .title {
    font-weight: normal;
    font-size: 14px;
    margin-right: 20px;
  }
}
</style>
